import React from "react";
import {Link} from "gatsby";
import webinar from '../assets/image/Digma_splash-webinar-small.jpg';

import PageWrapper from "../components/PageWrapper";

import CTA from "../sections/CTA/CTA";

import SEO from '../components/SEO';
import imgC from "../assets/image/metaimg/showcase.jpg";

const Demo = () => {
  return (  
    <>
   
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Webinar - How to build an Interactive Video using Digma"
          description="Learn how to make an Interactive Video using the Digma Platform. Followed by Q & A Session."
          image={imgC}
        />                    
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/webinar">Webinar</Link>
              </li>
              </ol>
           </nav>
        </div>  
        <div className="pt-4 pt-md-6 pt-lg-10">
          <div className="container">
            {/* Section Padding */}
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div
                  className="text-center mb-11 mb-lg-10"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-delay={500}
                >
                  <h1 className="font-size-11 mb-5">How to build an Interactive Video</h1>
                  <h2 className="font-size-7 mb-0">
                    Next Webinar - Tue, 15th June 2021 @ 2pm GMT
                  </h2>
                </div>
              </div>
            </div>
            {/* End Section Padding */}
          </div>
        </div>        
        <div className="pt-5 pb-10 container text-center">
          <a target="_blank" rel="noreferrer" href="https://www.eventbrite.co.uk/e/how-to-make-an-interactive-video-workshop-with-digma-tickets-156232996097" to="webinar">
            <img src={webinar} className="w-100" style={{borderRadius:"10px"}} alt=""/>
          </a>  
        </div>   
        <div className="pt-5 pb-20 container">
          <h3>About this event</h3>
          <p>Stuart and Chris will introduce Digma and the benefits of using Interactive Video followed by a step by step tutorial showing the simplicity of creating your very own Interactive video on the Digma Platform. They will showcase the <Link to="/info/cms" title="CMS">CMS</Link> and <Link to="/interactive-video-player" title="interactive video player">Interactive Video Player</Link> with it's unique features and a mobile experience like no other.</p>
          <p>Please come join our <a href="https://www.eventbrite.co.uk/e/how-to-make-an-interactive-video-workshop-with-digma-tickets-156232996097" target="_blank" rel="noreferrer">FREE webinar</a> to learn more about Interactive Video, how it can Increase <b>User Engagement</b>, <b>Brand Awareness</b> and <b>Maximise sales</b>.</p>
       </div>
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default Demo;
